/* eslint-env browser */
/* global PARTYKIT_HOST */

import "./styles.css";
import PartySocket from "partysocket";
// Import the p5.js sketch functions
import { setup, draw, preload, handleIncomingRect, setUserId, getMyColor, getMyNote } from './sketch.js';
import { setupSound, playNote, context } from './sound.js';

// Add preload to window object for p5.js to find it
window.preload = preload;

// Add the p5.js functions to the global scope
window.setup = setup;
window.draw = draw;
window.handleIncomingRect = handleIncomingRect;
window.setUserId = setUserId;
window.getMyColor = getMyColor;
window.getMyNote = getMyNote;

/**
 * A PartySocket is like a WebSocket, but with more features.
 * It handles reconnection logic, buffering messages while it's offline, etc.
 * @type {PartySocket} - The connection object
 */
const conn = new PartySocket({
  // @ts-expect-error This should be typed as a global string
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

/**
 * Event listener to handle received messages.
 * @param {Event} event - The message event
 */
conn.addEventListener("message", function (event) {
  if (event.data === "ping") return;
  
  const message = JSON.parse(event.data);
  if (message.type === 'rectangle') {
    // Trigger sound based on state change
    if (message.userId !== conn.id) {
      //console.log("playing note", message.note, message.mousePressed);
      playNote(message.note, message.mousePressed);
    }

    handleIncomingRect(
      message.userId,
      message.x,
      message.y,
      message.mousePressed,
      message.color,
      message.note
    );

  }
});

/**
 * Event listener for when the connection opens.
 */
conn.addEventListener("open", function () {
  setUserId(conn.id);
});

// Add touch state tracking
let isTouching = false;

export async function handleInteraction(event) {
    // Only handle events on canvas
    const canvas = document.querySelector('canvas');
    if (!event.target === canvas) return;

    // Resume audio context on first interaction
    if (context && context.state === 'suspended') {
        await context.resume();
    }

    // Get coordinates
    const coords = getNormalizedCoords(event);
    if (!coords) return;

    const isPressed = event.type === 'mousedown' ? 1 :
                     event.type === 'mouseup' ? 0 :
                     event.buttons === 1 ? 1 : 0;

    // Skip move events if not pressed
    if (event.type === 'mousemove' && !isPressed) return;

    const message = {
        type: 'rectangle',
        userId: conn.id,
        mousePressed: isPressed,
        x: coords.x,
        y: coords.y,
        color: getMyColor(),
        note: getMyNote()
    };

    // Update local state immediately
    handleIncomingRect(
        conn.id, 
        coords.x, 
        coords.y, 
        message.mousePressed, 
        message.color,
        message.note
    );

    // Only play note on press/release, not during moves
    if (!['mousemove', 'touchmove'].includes(event.type)) {
        playNote(message.note, message.mousePressed);
    }
    
    // Send to others
    conn.send(JSON.stringify(message));
}

function getNormalizedCoords(event) {
  const canvas = document.querySelector('canvas');
  const rect = canvas.getBoundingClientRect();
  
  return {
    x: map(event.clientX - rect.left, 0, rect.width, 0, width),
    y: map(event.clientY - rect.top, 0, rect.height, 0, height)
  };
}

// Initialize sound when the page loads
setupSound().catch(err => console.error('Error setting up sound:', err));
