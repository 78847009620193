const { createDevice, MIDIEvent } = require("@rnbo/js");

let context;
let outputNode;
let synthDevice;
let shimmerevDevice;

async function setupSound() {
    const WAContext = window.AudioContext || window.webkitAudioContext;
    context = new WAContext();

    // Create gain node and connect it to audio output
    outputNode = context.createGain();
    outputNode.gain.value = 0.3;
    outputNode.connect(context.destination);
    
    try {
        // Fetch the exported patchers
        let response = await fetch("export/synth.export.json");
        const synthPatcher = await response.json();

        response = await fetch("export/rnbo.shimmerev.json");
        const shimmerevPatcher = await response.json();

        // Create the devices
        synthDevice = await createDevice({ context, patcher: synthPatcher });
        shimmerevDevice = await createDevice({ context, patcher: shimmerevPatcher });

        // Connect the devices
        synthDevice.node.connect(shimmerevDevice.node);
        shimmerevDevice.node.connect(outputNode);

    } catch (err) {
        if (synthDevice && !synthDevice.node.isConnected) {
            synthDevice.node.connect(outputNode);
        }
    }
}

function playNote(pitch, state) {
    let midiChannel = 0;
    let midiPort = 0;

    // Calculate velocity based on pitch height
    // Higher notes get lower velocity (softer)
    // Scale from 70 (lowest note) to 30 (highest note)
    const maxVelocity = 50;
    const minVelocity = 10;
    const lowestNote = 31;  // G0
    const highestNote = 89; // F5
    
    const velocity = Math.round(
        maxVelocity - ((pitch - lowestNote) / (highestNote - lowestNote)) * (maxVelocity - minVelocity)
    );

    // Format MIDI message based on state
    let midiMessage;
    if (state === 1) {
        midiMessage = [
            144 + midiChannel, // Note on
            pitch,
            velocity  // Use calculated velocity
        ];
    } else {
        midiMessage = [
            128 + midiChannel, // Note off
            pitch,
            0
        ];
    }

    let midiEvent = new MIDIEvent(context.currentTime * 1000, midiPort, midiMessage);
    synthDevice.scheduleEvent(midiEvent);
}

export { setupSound, playNote, context };