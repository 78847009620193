let canvas;
const CANVAS_SIZE = 800;
let myColor;
let myNote;
let rectangles = {};
let myUserId;
const RECT_SIZE = 100;
const OSCILLATION_SPEED = 0.03;  // Controls speed of size change
const OSCILLATION_AMOUNT = 10;  // Controls amount of size change
let backgroundImage;

import { context, playNote } from './sound.js';
import { handleInteraction } from './client.js';

// C Phrygian scale across multiple octaves
const C_PHRYGIAN_SCALE = [
  // Lowest octave
  24,  // C0
  25,  // Db0
  27,  // Eb0
  29,  // F0
  31,  // G0
  32,  // Ab0
  34,  // Bb0

  // Low octave
  36,  // C1
  37,  // Db1
  39,  // Eb1
  41,  // F1
  43,  // G1
  44,  // Ab1
  46,  // Bb1
  
  // Middle-low octave
  48,  // C2
  49,  // Db2
  51,  // Eb2
  53,  // F2
  55,  // G2
  56,  // Ab2
  58,  // Bb2
  
  // Middle octave
  60,  // C3
  61,  // Db3
  63,  // Eb3
  65,  // F3
  67,  // G3
  68,  // Ab3
  70,  // Bb3
  
  // High octave
  72,  // C4
  73,  // Db4
  75,  // Eb4
  77,  // F4
  79,  // G4
  80,  // Ab4
  82   // Bb4
];

function preload() {
  backgroundImage = loadImage('img/img-5.jpg');
}

function setup() {
  const size = Math.min(windowWidth, windowHeight) * 0.9;
  canvas = createCanvas(size, size);
  canvas.style('display', 'block');
  canvas.style('margin', 'auto');
  
  colorMode(HSB, 100);
  myColor = generateColor();
  myNote = generateNote();
  
  backgroundImage.resize(width, height);

  const canvasElement = document.querySelector('canvas');
  canvasElement.addEventListener('mousedown', handleInteraction);
  canvasElement.addEventListener('mouseup', handleInteraction);
  canvasElement.addEventListener('mousemove', handleInteraction);
}

function draw() {
  image(backgroundImage, 0, 0);
  
  Object.entries(rectangles).forEach(([userId, rectangle]) => {
    if (rectangle.state === 1 || rectangle.alpha > 0) {
      noFill();
      stroke(rectangle.color[0], rectangle.color[1], rectangle.color[2], rectangle.alpha);
      strokeWeight(1);

      // Calculate oscillating size when state is 1
      let currentSize = RECT_SIZE;
      if (rectangle.state === 1) {
        currentSize = RECT_SIZE + sin(frameCount * OSCILLATION_SPEED) * OSCILLATION_AMOUNT;
      }
      
      rect(
        rectangle.x - currentSize/2, 
        rectangle.y - currentSize/2, 
        currentSize, 
        currentSize
      );
      
      if (rectangle.state === 0 && rectangle.alpha > 0) {
        rectangle.alpha = max(0, rectangle.alpha - 5);
        
        if (rectangle.alpha === 0) {
          delete rectangles[userId];
        }
      }
    }
  });
}

function generateColor() {
  const hue = random(100);
  const saturation = random(15, 30);
  const brightness = random(50, 70);
  const baseAlpha = 70;
  
  return [hue, saturation, brightness, baseAlpha];
}

function generateNote() {
  const index = Math.floor(random(C_PHRYGIAN_SCALE.length));
  return C_PHRYGIAN_SCALE[index];
}

function mapToScale(note) {
  let closest = C_PHRYGIAN_SCALE[0];
  let minDistance = Math.abs(note - closest);

  for (const scaleNote of C_PHRYGIAN_SCALE) {
    const distance = Math.abs(note - scaleNote);
    if (distance < minDistance) {
      minDistance = distance;
      closest = scaleNote;
    }
  }

  return closest;
}

function handleIncomingRect(userId, x, y, state, userColor, userNote) {
  rectangles[userId] = {
    x: x,
    y: y,
    state: state,
    color: userColor,
    note: userNote,
    alpha: 255
  };
}

function setUserId(id) {
  myUserId = id;
}

function getMyColor() {
  return myColor;
}

function getMyNote() {
  return myNote;
}

export { setup, draw, preload, handleIncomingRect, setUserId, getMyColor, getMyNote };
